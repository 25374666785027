import { State, Action } from 'src/types'
import {
  SET_SELECTED_PARTS,
  SET_ALL_LEATHER_COLOR,
  SET_BASE_MODEL,
  SET_DOMINANT_ARM,
  SET_LEATHER_COLOR,
  SET_EDGE_COLOR,
  SET_LININGS,
  SET_HAMIDASHI,
  SET_FINGER_GUARD_TYPE,
  SET_STITCH_COLOR,
  SET_EMBROIDERIES,
  ADD_EMBROIDERY,
  REMOVE_EMBROIDERY,
  SET_WEB_COLOR,
  SET_WEB_COLOR_2,
  SET_THUMB_HOOK,
  SET_LITTLE_HOOK,
  SET_INDEX_COVER_COLOR,
  SET_PERSONAL,
  SET_LIST_BELT_COLOR,
  SET_THUMB_COLOR,
  SET_UNDER_WEB_COLOR,
  SET_BOOMERANG_COLOR,
  SET_LACE_COLOR,
  SET_ATOMS_LABEL,
  SET_SIZE,
  SET_MOUTON_COLOR,
  SET_TANNER,
  SET_LOOP_OF_RING_FINGER,
  SET_FIGURE_PANEL_NUMBER
} from 'src/features/brands/atoms/japan/constants/actionType'
import { colorInitialState, initialEmbroideryState } from 'src/features/brands/atoms/japan/reducer'
import { leatherColorObjsJa } from 'src/features/brands/atoms/japan/constants/colors'
import {
  baseInitialState,
  initialPersonalState,
  unselectedState,
  unselectedColorState,
  baseInitialStateEn,
  unselectedStateEn,
  colorInitialStateEn,
  unselectedColorStateEn,
  unselectedStateKo,
  unselectedColorStateKo,
  baseInitialStateKo,
  colorInitialStateKo
} from 'src/features/brands/reducer'

// 一塁手用ミット
export const initialStateFirstMitt: State = {
  ...baseInitialState,
  kinds: unselectedState,
  tanner: unselectedState,
  loopOfRingFinger: unselectedState,
  loopOfRingFingerColor: unselectedColorState,
  webParts: unselectedState,
  ...colorInitialState,
  thumb: unselectedColorState,
  underWeb: unselectedColorState,
  boomerang: unselectedColorState,
  mouton: unselectedState,
  atomsLabel: unselectedState,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}
export const initialStateFirstMittEn: State = {
  ...baseInitialStateEn,
  ...colorInitialStateEn,
  tanner: unselectedStateEn,
  loopOfRingFinger: unselectedStateEn,
  loopOfRingFingerColor: unselectedColorStateEn,
  webParts: unselectedStateEn,
  thumb: unselectedColorStateEn,
  underWeb: unselectedColorStateEn,
  boomerang: unselectedColorStateEn,
  mouton: unselectedStateEn,
  atomsLabel: unselectedStateEn,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}
export const initialStateFirstMittKo: State = {
  ...baseInitialStateKo,
  ...colorInitialStateKo,
  tanner: unselectedStateKo,
  loopOfRingFinger: unselectedStateKo,
  loopOfRingFingerColor: unselectedColorStateKo,
  webParts: unselectedStateKo,
  thumb: unselectedColorStateKo,
  underWeb: unselectedColorStateKo,
  boomerang: unselectedColorStateKo,
  mouton: unselectedStateKo,
  atomsLabel: unselectedStateKo,
  embroideries: [initialEmbroideryState],
  personal: initialPersonalState,
  figurePanelNum: 1
}

export const firstMittReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TANNER:
      return { ...state, tanner: action.tanner }
    case SET_SELECTED_PARTS:
      return { ...state, parts: action.parts }
    case SET_ALL_LEATHER_COLOR:
      return {
        ...state,
        all: action.all,
        catchFace: action.all,
        web: action.all,
        web2: action.all,
        thumbHook: action.all,
        littleHook: action.all,
        edge: action.all,
        indexCover: action.all,
        // indexAndThumb: action.all,
        listBelt: action.all,
        // 裏革にはない色が「全体」として指定されたら、裏革のみ既存stateを維持させる
        // 裏革にもある色が「全体」として指定されたらもちろん反映を許可
        linings: leatherColorObjsJa.some((x) => x.color === action.all.color) ? action.all : state.linings, // 裏革
        // 内野手用グラブ
        // 一塁手用ミット
        thumb: action.all,
        underWeb: action.all,
        boomerang: action.all,
        loopOfRingFingerColor: action.all
      }
    case SET_BASE_MODEL:
      return { ...state, baseModel: action.baseModel, kinds: action.kinds }
    case SET_DOMINANT_ARM:
      return { ...state, dominantArm: action.dominantArm }
    case SET_SIZE:
      return { ...state, size: action.size }
    case SET_LOOP_OF_RING_FINGER:
      return { ...state, loopOfRingFinger: action.loopOfRingFinger, figurePanelNum: 1 }
    case SET_LEATHER_COLOR:
      return { ...state, catchFace: action.catchFace }
    case SET_EDGE_COLOR:
      return { ...state, edge: action.edge }
    case SET_WEB_COLOR:
      return { ...state, web: action.web }
    case SET_WEB_COLOR_2:
      return { ...state, web2: action.web2 }
    // 一塁手用ミット
    case SET_LIST_BELT_COLOR:
      return { ...state, listBelt: action.listBelt }
    case SET_THUMB_COLOR:
      return { ...state, thumb: action.thumb }
    case SET_UNDER_WEB_COLOR:
      return { ...state, underWeb: action.underWeb }
    case SET_BOOMERANG_COLOR:
      return { ...state, boomerang: action.boomerang }
    case SET_LININGS:
      return { ...state, linings: action.linings, loopOfRingFingerColor: action.linings, figurePanelNum: 1 }
    case SET_HAMIDASHI:
      return { ...state, hamidashi: action.hamidashi }
    case SET_FINGER_GUARD_TYPE:
      return { ...state, fingerGuardType: action.fingerGuardType, figurePanelNum: 1 }
    case SET_LACE_COLOR:
      return { ...state, lace: action.lace }
    case SET_ATOMS_LABEL:
      return { ...state, atomsLabel: action.atomsLabel, figurePanelNum: 1 }
    case SET_STITCH_COLOR:
      return { ...state, stitch: action.stitch }
    case SET_EMBROIDERIES:
      return { ...state, embroideries: action.embroideries, figurePanelNum: 1 }
    case ADD_EMBROIDERY: // incrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case REMOVE_EMBROIDERY: // decrementの方が合っている気がする
      return { ...state, embroideries: action.embroideries }
    case SET_THUMB_HOOK:
      return { ...state, thumbHook: action.thumbHook }
    case SET_LITTLE_HOOK:
      return { ...state, littleHook: action.littleHook }
    case SET_INDEX_COVER_COLOR:
      return { ...state, indexCover: action.indexCover }
    case SET_MOUTON_COLOR:
      return { ...state, mouton: action.mouton }
    case SET_PERSONAL:
      return { ...state, personal: action.personal }
    case SET_FIGURE_PANEL_NUMBER:
      return { ...state, figurePanelNum: action.figurePanelNum }
    default:
      return state
  }
}
